import React from "react";
import { MdStarRate, MdOutlineStarRate } from "react-icons/md";

const Note = ({ note }) => {
  const full = note;
  const empty = 5 - note;
  let result = [];
  if (full > 0) {
    for (let index = 1; index <= full; index++) {
      result.push(<MdStarRate size={24} key={`full-${index}`} />);
    }
  }
  if (empty > 0) {
    for (let index = 1; index <= empty; index++) {
      result.push(<MdOutlineStarRate size={24} key={`empty-${index}`} />);
    }
  }
  return <div className="note">{result}</div>;
};

export default Note;
