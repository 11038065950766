import React from "react";
import Testimonial from "../atoms/Testimonial";

const Testimonials = ({ testimonials, testimonialTitle }) => {
  return (
    <section
      id="testimonial"
      className="bg-dark-gray bubble-background full-width padding-small block--testimonials"
    >
      <div className="grid-container">
        <h2 className="text-white text-centered">{testimonialTitle}</h2>
        <div className="block--testimonials__container">
          {testimonials.map((testimonial, key) => (
            <Testimonial testimonial={testimonial} key={key} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
