import { renderRichText } from "gatsby-source-contentful/rich-text";
import React from "react";
import { FaQuoteLeft } from "react-icons/fa";
import Excerpt from "./Excerpt";
import Note from "./Note";

const Testimonial = ({ testimonial }) => {
  const { author, text, note } = testimonial;
  return (
    <article className="card--testimonial">
      <div className="card--testimonial__content">
        <Note note={note} />
        <div className="text-medium text-secondary font-secondary mb-1 letter-spacing">
          {author}
        </div>
        <FaQuoteLeft size={30} />
        {text && <Excerpt text={renderRichText(text)} />}
      </div>
    </article>
  );
};

export default Testimonial;
