import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { Link } from "gatsby";

const SectionArticle = ({ article }) => {
  const { title, image, isImageLeft, textContent, link, buttonLabel, bgColor } =
    article;
  return (
    <section
      className={`mod full-width ${
        bgColor === "Clair"
          ? "bg-light"
          : bgColor === "Foncé"
          ? "bg-dark"
          : "bg-white"
      }`}
    >
      <div className="grid-container">
        <article className="block--article">
          <div
            className={`grid-x grid-margin-x grid-margin-y align-center align-stretch ${
              isImageLeft ? "" : "flex-dir-row-reverse"
            }`}
          >
            {image && (
              <div className="cell medium-8 large-6">
                <div className="block--article__img">
                  <GatsbyImage
                    image={getImage(image)}
                    alt={image.title || title}
                  />
                </div>
              </div>
            )}

            <div
              className={`cell ${
                image ? "medium-8 large-6" : "medium-10 large-12"
              }`}
            >
              <div className="block--article__content">
                {title && (
                  <h2
                    className={`mb-2 letter-spacing underlined ${
                      image ? "underlined--left" : ""
                    }`}
                  >
                    {title}
                  </h2>
                )}
                {textContent && (
                  <div className="bloc--article__text">
                    {renderRichText(textContent)}
                  </div>
                )}

                {link && buttonLabel && (
                  <Link
                    className={`button black mt-1-5 mb-0 ${
                      image ? "right" : "centered"
                    }`}
                    to={link}
                  >
                    {buttonLabel}
                  </Link>
                )}
              </div>
            </div>
          </div>
        </article>
      </div>
    </section>
  );
};

export default SectionArticle;
