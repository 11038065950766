import * as React from "react";
import Layout from "../components/layout";
import Head from "../components/head";
import Hero from "../components/sections/Hero";
import { graphql, useStaticQuery } from "gatsby";
import ContactLink from "../components/sections/ContactLink";
import SectionArticle from "../components/sections/SectionArticle";
import FeaturedParcours from "../components/sections/FeaturedParcours";
import FeaturedNews from "../components/sections/FeaturedNews";
import Testimonials from "../components/sections/Testimonials";

// markup
const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      home: contentfulPageDaccueil {
        title
        subtitle
        image {
          gatsbyImageData(width: 2000, height: 900)
        }
        mobileImage: image {
          gatsbyImageData(width: 800, height: 700)
        }
        seoTitle
        seoDescription
        parcoursTitle
        parcoursText {
          raw
        }
        featuredParcours {
          title
          slug
          accroche
          excerpt {
            excerpt
          }
          difficulty
          time
          image {
            gatsbyImageData(
              layout: FIXED
              placeholder: BLURRED
              width: 370
              height: 220
            )
          }
        }
        parcoursButton
        blocDeContenu1 {
          title
          link
          isImageLeft
          image {
            gatsbyImageData(
              layout: FULL_WIDTH
              width: 600
              placeholder: DOMINANT_COLOR
            )
            title
          }
          bgColor
          buttonLabel
          textContent {
            raw
          }
        }
        blocDeContenu2 {
          title
          link
          isImageLeft
          image {
            gatsbyImageData(
              layout: FULL_WIDTH
              width: 600
              placeholder: DOMINANT_COLOR
            )
            title
          }
          bgColor
          buttonLabel
          textContent {
            raw
          }
        }
        isFeaturedNews
        newsTitle
        allnewsButton
        featuredNews {
          title
          category
          date
          slug
          createdAt(formatString: "DD MM YYYY")
          image {
            gatsbyImageData(
              layout: FIXED
              placeholder: BLURRED
              width: 370
              height: 220
            )
          }
        }

        isTestimonials
        testimonialTitle
        testimonials {
          author
          note
          text {
            raw
          }
          createdAt
        }
      }
    }
  `);

  const home = data.home;

  const cta1 = {
    link: "/parcours",
    label: "Nos parcours",
  };
  const cta2 = {
    link: "#next",
    label: "Découvrir",
  };

  return (
    <Layout>
      <Head
        title={home.seoTitle || home.title}
        description={home.seoDescription}
      />
      <Hero
        title={home.title}
        subtitle={home.subtitle}
        image={home.image}
        mobileImage={home.mobileImage}
        cta1={cta1}
        cta2={cta2}
      />
      {home.blocDeContenu1?.textContent && (
        <SectionArticle article={home.blocDeContenu1} />
      )}
      <FeaturedParcours
        parcoursTitle={home.parcoursTitle}
        parcoursText={home.parcoursText}
        featuredParcours={home.featuredParcours}
        parcoursButton={home.parcoursButton}
      />
      {home.blocDeContenu2?.textContent && (
        <SectionArticle article={home.blocDeContenu2} />
      )}
      {home.isFeaturedNews && (
        <FeaturedNews
          newsTitle={home.newsTitle}
          featuredNews={home.featuredNews}
          newsButton={home.allnewsButton}
        />
      )}
      {home.isTestimonials && (
        <Testimonials
          testimonialTitle={home.testimonialTitle}
          testimonials={home.testimonials}
        />
      )}

      <ContactLink />
    </Layout>
  );
};

export default IndexPage;
